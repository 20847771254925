<template>
<div class="planDetail">
  <div class="plan-header"
       v-if="detail.solutionHead"
    :style="{'background-image':detail.solutionHead.imgUrl?`url(${detail.solutionHead.imgUrl})`:''}"
  >
        <div>
          <div class="type-info">行业方案-{{detail.solutionTypeName}}</div>
          <div class="title">{{detail.solutionName}}</div>
          <div class="info">{{detail.solutionDesc}}</div>
        </div>
  </div>
  <div class="feature" v-if="detail.solutionFeatures">
      <div class="title">{{detail.solutionFeatures.title}}</div>
      <div class="info">{{detail.solutionFeatures.description}}</div>
      <div class="content">
          <div class="content-item"
               v-for="(item,index) in detail.solutionFeatures.solutionFeatureList"
               :key="index"
          >
            <div><img :src="item.icon" width="75px" height="75px" alt=""></div>
            <div class="title">{{item.title}}</div>
            <div class="info">{{item.description}}</div>
          </div>
      </div>
  </div>
  <div class="highlights"
       v-if="detail.solutionHighlights"
       :style="{'background-image':detail.solutionHighlights.imgUrl?`url(${detail.solutionHighlights.imgUrl})`:''}"

  >
      <div class="content-info">
            <div class="title">方案亮点</div>
            <div class="brightSpot-content">
              <div class="item-info"
                   v-for="(item,index) in detail.solutionHighlights.solutionHighlightsList"
                   :key="index"
              >
                <div class="title">{{item.title}}</div>
                <div class="info">{{item.description}}</div>
              </div>
            </div>
      </div>
  </div>
  <div class="scenes" v-if="detail.solutionScenes">
      <div class="title">应用场景</div>
      <div class="scenes-content">
          <div class="info">{{detail.solutionScenes[scenesIndex].sceneDesc}}</div>
          <img :src="detail.solutionScenes[scenesIndex].scenePicture" width="100%" height="600px">
      </div>
      <div class="tools-box">
          <div class="tools-item"
               v-for="(item,index) in detail.solutionScenes"
               :key="index"
               :class="scenesIndex==index?'active':''"
               @click="scenesIndex=index"
          >
              {{item.sceneTypeName}}
          </div>
      </div>
  </div>
  <div class="more-project" v-if="detail.caseList.length>0">
    <div class="title">实战案例</div>
    <div class="info">提供充分的技术储备，服务支持和智慧标杆案例</div>
    <el-carousel class="project-carousel"
                 ref="projectCarousel"
                 :autoplay="false"
                 indicator-position="none"
                 height="400px"

    >
      <el-carousel-item
          v-for="(item,index) in detail.caseList"
          :key="index"
      >
        <div
            class="project-item"
            @click="goProjectDetail(item.id)"

        >
          <div class="text-content">
              <div>
                <div class="title">
                  {{item.caseName}}
                </div>
                <div class="text-info">
                  {{item.caseDesc}}
                </div>
                <div style="margin-top: 5px;color: #797979;font-size: 14px">{{item.caseExtras}}</div>
                <div style="margin-top: 80px">了解详情 ></div>
              </div>
          </div>
          <div class="img-content">
            <img :src="item.casePicture" style="object-fit: contain" width="660px" height="400px" alt="">
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
  <!--  了解其他案例-->
  <div class="more-plan">
    <div class="title">了解其他方案</div>
    <el-carousel class="plan-carousel"
                 ref="planCarousel"
                 :autoplay="false"
                 indicator-position="none"
                 height="520px"

    >
      <el-carousel-item
          v-for="(item,index) in planList"
          :key="index"
      >
        <div
            class="project-item"
            @click="goPlanDetail(item.id)"

        >
          <div><img :src="item.solutionPicture" width="900px" height="400px" alt=""></div>
          <div style="margin-top: 10px;font-size: 18px;font-weight: bold;color: #797979">{{item.solutionTypeName}}</div>
          <div style="margin-top: 5px;font-size: 16px">{{item.solutionName}}</div>
          <div style="margin-top: 5px;font-size: 14px;color: #797979">{{item.shufflingInfo}}</div>
          <div style="margin-top: 10px">了解详情></div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</div>
</template>

<script>
export default {
  name: "planDetail",
  data(){
    return {
      detail:{},
      scenesIndex:0,
      planList:[],
    }
  },
  created() {
      this.getDetail();
      this.getPlanList();
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  methods:{
    getDetail(){
      this.$get('/website/solution/'+this.$route.query.id).then((res)=>{
            this.detail=res.data;
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    goProjectDetail(id){
      this.$router.push({
        name:'projectDetail',
        query:{
          id:id
        }
      })
    },
    goPlanDetail(id){
      this.$router.push({
        name:'planDetail',
        query:{
          id:id
        }
      })
    },
    getPlanList(){
      this.$get("/website/solution/list").then((res)=>{
          this.planList=res.rows;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.planDetail{
  padding-top: 64px;
  .plan-header{
    width: 100%;
    height: 680px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .type-info{
      font-size: 32px;
    }
    .title{
      font-size: 48px;
      margin-top: 20px;

    }
    .info{
      font-size: 22px;
    }
  }
  .highlights{
    margin: 80px 0;
    width: 100%;
    height: 680px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .content-info{
      width: 50%;
      height: 100%;
      padding: 40px 70px;
      color: #fff;
      >.title{
        font-size: 32px;
      }
      .brightSpot-content{
        height: 520px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 30px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 0px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background-color: rgba(0, 0, 0, 0.2);
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          // border-radius: 10px;
          background: transparent;
        }
      }
      .item-info{
        .title{
          font-size: 18px;
          margin: 30px 0 10px 0;
        }
        .info{
          color: #797979;
          font-size: 14px;
        }
      }
    }
  }
  .feature{
      text-align: center;
      color: black;
      padding: 80px 0;
      >.title{
        font-size: 38px;
      }
      >.info{
        font-size: 32px;
        margin-top: 30px;
      }
      .content{
        display: flex;
        justify-content: space-around;
        width: 80%;
        margin: 0 auto;
        margin-top: 80px;
        .content-item{
          width: 20%;
          .title{
            font-size: 24px;
            font-weight: bold;
            text-align: center;
          }
          .info{
            margin-top: 20px;
            text-align: left;
            font-size: 14px;
            color: #797979;
          }
        }
      }
  }
  .scenes{
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 80px;
    >.title{
      font-size: 38px;
      padding-top: 80px;
      padding-bottom: 20px;
    }
    .tools-box{
      width: 1000px;
      margin: 0 auto;
      margin-top: 30px;
      border-top: 1px solid #797979;
      .tools-item{
        display: inline-block;
        padding: 10px 10px;
        margin: 0 20px;
        cursor: pointer;
        position: relative;
        top: -1px;
        border-top: 2px solid transparent;
        font-size: 16px;
        color: #797979;
      }
      .tools-item.active{
        color: black;
        border-top-color: black;
      }
    }
    .scenes-content{
      .info{
        margin-bottom: 80px;
        color: #797979;
      }
    }
  }
  .more-project{
    width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 80px;
    .title{
      font-size: 38px;
      text-align: center;
      margin-bottom: 20px;
    }
    >.info{
      text-align: center;
      margin-bottom: 80px;
    }
  }
  .more-plan{
    width: 900px;
    margin: 0 auto;
    .title{
      font-size: 38px;
      text-align: center;
      padding: 80px 0;
    }
    >.info{
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .planCarousel{

  }
  .project-carousel{
    width: 1200px;
    margin: 0 auto;
    .project-item{
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      .text-content{
        width: 500px;
        text-align: left;
        display: flex;
        align-items: center;
        .title{
          text-align: left;
          font-size: 28px;
        }
      }
    }
  }
}
</style>
